// this file contains the names of the tables and the list of members of different data entities
const dataModels = {
  /*
	'Kundenkontakt': {
    tableOnServer: 'kundenkontakt',
		table: 'kundenkontakt',
		nameScope: 'customerContact'
	},
	*/
  Dialog: {
    tableOnServer: "dialog",
    table: "dialog",
  },

  Mandant: {
    tableOnServer: "mandant",
    table: "mandant",
  },

  Kunde: {
    tableOnServer: "kunde",
    table: "kunde",
    nameScope: "customer",
    columns: [
      {
        nameOnServer: "NameFirma_5",
        nameLocal: "NameFirma_5",
        nameScope: "lastName",
        type: "string",
      },
      {
        nameOnServer: "AnredePersoenlichDu_176",
        nameLocal: "AnredePersoenlichDu_176",
        nameScope: "familiar",
        type: "boolean",
      },
      {
        nameOnServer: "Titel_8",
        nameLocal: "Titel_8",
        nameScope: "academicTitle",
        type: "string",
      },
      {
        nameOnServer: "Vorname_9",
        nameLocal: "Vorname_9",
        nameScope: "firstName",
        type: "string",
      },
      {
        nameOnServer: "KundenNr_10",
        nameLocal: "KundenNr_10",
        nameScope: "number",
        type: "string",
      },
      {
        nameOnServer: "Strasse_11",
        nameLocal: "Strasse_11",
        nameScope: "street",
        type: "string",
      },
      {
        nameOnServer: "PLZ_12",
        nameLocal: "PLZ_12",
        nameScope: "zipCode",
        type: "string",
      },
      {
        nameOnServer: "Ort_13",
        nameLocal: "Ort_13",
        nameScope: "city",
        type: "string",
      },
      {
        nameOnServer: "Telefon1_15",
        nameLocal: "Telefon1_15",
        nameScope: "telephone1",
        type: "string",
      },
      {
        nameOnServer: "Telefon2_16",
        nameLocal: "Telefon2_16",
        nameScope: "telephone2",
        type: "string",
      },
      {
        nameOnServer: "Fax_17",
        nameLocal: "Fax_17",
        nameScope: "fax",
        type: "string",
      },
      {
        nameOnServer: "E_Mail_18",
        nameLocal: "E_Mail_18",
        nameScope: "email",
        type: "string",
      },
      {
        nameOnServer: "Geburtsdatum_51",
        nameLocal: "Geburtsdatum_51",
        nameScope: "birthday",
        type: "date",
      },
      {
        nameOnServer: "Homepage_19",
        nameLocal: "Homepage_19",
        nameScope: "homepage",
        type: "string",
      },
      {
        nameOnServer: "Bemerkung_30",
        nameLocal: "Bemerkung_30",
        nameScope: "remarks",
        type: "string",
      },
      {
        nameOnServer: "UID-Nummer_41",
        nameLocal: "UID_Nummer_41",
        nameScope: "numberUID",
        type: "string",
      },
      {
        nameOnServer: "NN_9999",
        nameLocal: "idSexAtClient_9999", // 9999 => not synchronized
        nameScope: "idSexAtClient",
        type: "string",
      },
      {
        nameOnServer: "Geschlecht_52",
        nameLocal: "Geschlecht_52",
        nameScope: "idSexAtServer",
        type: "string",
      },
      {
        nameOnServer: "uuidSex_9999",
        nameLocal: "uuidSex",
        nameScope: "uuidSex",
        type: "string",
      },
      {
        nameOnServer: "NN_9999",
        nameLocal: "idCountryAtClient_9999", // 9999 => not synchronized
        nameScope: "idCountryAtClient",
        type: "string",
      },
      {
        nameOnServer: "Land_14",
        nameLocal: "Land_14",
        nameScope: "idCountryAtServer",
        type: "string",
      },
      {
        nameOnServer: "uuidCountry_9999",
        nameLocal: "uuidCountry",
        nameScope: "uuidCountry",
        type: "string",
      },
      {
        nameOnServer: "NN_9999",
        nameLocal: "idsCustomerCategoriesAtClient_9999", // 9999 => not synchronized
        nameScope: "idsCustomerCategoriesAtClient",
        type: "string",
      },
      {
        nameOnServer: "Kundenkategorie_21",
        nameLocal: "Kundenkategorie_21",
        nameScope: "idsCustomerCategoriesAtServer",
        type: "string",
      },
      {
        nameOnServer: "uuidsCustomerCategories_9999",
        nameLocal: "uuidsCustomerCategories",
        nameScope: "uuidsCustomerCategories",
        type: "string",
      },
      {
        nameOnServer: "NN_9999",
        nameLocal: "idPaymentTypeAtClient_9999", // 9999 => not synchronized
        nameScope: "idPaymentTypeAtClient",
        type: "string",
      },
      {
        nameOnServer: "Zahlungsart_61",
        nameLocal: "Zahlungsart_61",
        nameScope: "idPaymentTypeAtServer",
        type: "string",
      },
      {
        nameOnServer: "uuidPaymentType_9999",
        nameLocal: "uuidPaymentType",
        nameScope: "uuidPaymentType",
        type: "string",
      },
      {
        nameOnServer: "NN_9999",
        nameLocal: "idPaymentTargetAtClient_9999", // 9999 => not synchronized
        nameScope: "idPaymentTargetAtClient",
        type: "string",
      },
      {
        nameOnServer: "Zahlungsziel_62",
        nameLocal: "Zahlungsziel_62",
        nameScope: "idPaymentTargetAtServer",
        type: "string",
      },
      {
        nameOnServer: "uuidPaymentTarget_9999",
        nameLocal: "uuidPaymentTarget",
        nameScope: "uuidPaymentTarget",
        type: "string",
      },
      {
        nameOnServer: "NN_9999",
        nameLocal: "idPricelistAtClient_9999", // 9999 => not synchronized
        nameScope: "idPricelistAtClient",
        type: "string",
      },
      {
        nameOnServer: "Preisliste_60",
        nameLocal: "Preisliste_60",
        nameScope: "idPricelistAtServer",
        type: "string",
      },
      {
        nameOnServer: "uuidPricelist_9999",
        nameLocal: "uuidPricelist",
        nameScope: "uuidPricelist",
        type: "string",
      },
      {
        nameOnServer: "Rabatt_69",
        nameLocal: "Rabatt_69",
        nameScope: "discount",
        type: "float",
      },
      {
        nameOnServer: "Hobbies / Vorlieben_56",
        nameLocal: "Stichworte_56",
        nameScope: "keywords",
        type: "string",
      },
      {
        nameOnServer: "Ust-befreit_122",
        nameLocal: "Ust_befreit_122",
        nameScope: "taxExempt",
        type: "boolean",
      },
      {
        nameOnServer: "letzter Kontakt/Werbung_142",
        nameLocal: "letzter_Kontakt_Werbung_142",
        nameScope: "lastContact",
        type: "date",
      },
      {
        nameOnServer: "DummyDate2_163",
        nameLocal: "naechster_Kontakt_163",
        nameScope: "nextContact",
        type: "date",
      },
      {
        nameOnServer: "DummyINT_185",
        nameLocal: "Kontaktfrequenz_Tage_185",
        nameScope: "contactFrequencyDays",
        type: "integer",
      },
      {
        nameOnServer: "Wiederanruf Thema_238",
        nameLocal: "Wiederanruf_Thema_238",
        nameScope: "nextContactTopic",
        type: "string",
      },
    ],
  },

  Währung: {
    tableOnServer: "währung",
    table: "waehrung",
  },

  Benutzer: {
    tableOnServer: "benutzer",
    table: "benutzer",
  },

  Artikel: {
    tableOnServer: "artikel",
    table: "artikel",
    nameScope: "article",
    columns: [
      {
        nameOnServer: "Artikelnr._5",
        nameLocal: "Artikelnr_5",
        nameScope: "number",
        type: "string",
      },
      {
        nameOnServer: "Bezeichnung_6",
        nameLocal: "Bezeichnung_6",
        nameScope: "name",
        type: "string",
      },
      {
        nameOnServer: "EAN-Code_8",
        nameLocal: "EAN_Code_8",
        nameScope: "ean",
        type: "string",
      },
      {
        nameOnServer: "Artikelgruppe_24",
        nameLocal: "Artikelgruppe_24",
        nameScope: "idArticleGroupAtServer",
        type: "number",
      },
      {
        nameOnServer: "uuidArticlegroup_9999",
        nameLocal: "uuidArticlegroup",
        nameScope: "uuidArticlegroup",
        type: "string",
      },
      {
        nameOnServer: "Beschreibung_33",
        nameLocal: "Beschreibung_33",
        nameScope: "description",
        type: "string",
      },
      {
        nameOnServer: "Beschreibung 2_34",
        nameLocal: "Beschreibung2_34",
        nameScope: "description2",
        type: "string",
      },
      {
        nameOnServer: "Gewicht kg_39",
        nameLocal: "GewichtKg_39",
        nameScope: "weightKgUnit",
        type: "number",
      },
      {
        nameOnServer: "Steuersatz_62",
        nameLocal: "Steuersatz_62",
        nameScope: "idTaxRateAtServer",
        type: "number",
      },
      {
        nameOnServer: "uuidTaxRate_9999",
        nameLocal: "uuidTaxRate",
        nameScope: "uuidTaxRate",
        type: "string",
      },
      {
        nameOnServer: "Gesamtsteuersatz_63",
        nameLocal: "Gesamtsteuersatz_63",
        nameScope: "taxFactor",
        type: "number",
      },
      {
        nameOnServer: "Provisionsstufe_65",
        nameLocal: "Provisionsstufe_65",
        nameScope: "commissionLevel",
        type: "number",
      },
      {
        nameOnServer: "Preis Brutto_69V",
        nameLocal: "PreisBrutto_69V",
        nameScope: "priceGrossV",
        type: "string",
      },
      {
        nameOnServer: "Preis Netto_70V",
        nameLocal: "PreisNetto_70V",
        nameScope: "priceNetV",
        type: "string",
      },
      {
        nameOnServer: "Standard DB_71V",
        nameLocal: "StandardDB_71V",
        nameScope: "standardDBV",
        type: "string",
      },
      {
        nameOnServer: "Standard DB %_72",
        nameLocal: "StandardDBProzent_72",
        nameScope: "standardDBPercent",
        type: "string",
      },
      {
        nameOnServer: "Kalk_EK_Preis_per_Stk_103V",
        nameLocal: "Kalk_EK_Preis_per_Stk_103V",
        nameScope: "calcCostPricePerPieceV",
        type: "string",
      },
      {
        nameOnServer: "Bezugskosten_104V",
        nameLocal: "Bezugskosten_104V",
        nameScope: "purchaseCostsV",
        type: "string",
      },
      {
        nameOnServer: "Kosten_Material_105V",
        nameLocal: "Kosten_Material_105V",
        nameScope: "materialCostsV",
        type: "string",
      },
      {
        nameOnServer: "Einstandspreis_106V",
        nameLocal: "Einstandspreis_106V",
        nameScope: "costPriceV",
        type: "string",
      },
      {
        nameOnServer: "Kosten_Arbeit_107V",
        nameLocal: "Kosten_Arbeit_107V",
        nameScope: "costsLabourV",
        type: "string",
      },
      {
        nameOnServer: "Kosten_Ressourcen_108V",
        nameLocal: "Kosten_Ressourcen_108V",
        nameScope: "costsRessourcesV",
        type: "string",
      },
      {
        nameOnServer: "Sonstige_Kosten_109V",
        nameLocal: "Sonstige_Kosten_109V",
        nameScope: "costsOtherV",
        type: "string",
      },
      {
        nameOnServer: "Gesamtkosten / Stk._110V",
        nameLocal: "GesamtkostenProStk_110V",
        nameScope: "purchasePriceUnitV",
        type: "string",
      },
      {
        nameOnServer: "Gemeinkostenanteil_126",
        nameLocal: "Gemeinkostenanteil_126",
        nameScope: "overheadShare",
        type: "string",
      },
      {
        nameOnServer: "Bezeichnung_5_9999",
        nameLocal: "ArtikelgruppeName_9999",
        nameScope: "articleGroupName",
        type: "string",
      },
    ],
  },

  Aufgabe: {
    tableOnServer: "aufgabe",
    table: "aufgabe",
    nameScope: "task",
    columns: [
      {
        nameOnServer: "Titel_5",
        nameLocal: "Titel_5",
        nameScope: "title",
        type: "string",
      },
      {
        nameOnServer: "DatumFaellig_7",
        nameLocal: "DatumFaellig_7",
        nameScope: "due",
        type: "date",
      },
      {
        nameOnServer: "NN_9999",
        nameLocal: "idTaskCategoryAtClient_9999",
        nameScope: "idTaskCategoryAtClient",
        type: "integer",
      },
      {
        nameOnServer: "Kategorie_9",
        nameLocal: "Kategorie_9",
        nameScope: "idTaskCategoryAtServer",
        type: "integer",
      },
      {
        nameOnServer: "uuidTaskCategory_9999",
        nameLocal: "uuidTaskCategory",
        nameScope: "uuidTaskCategory",
        type: "string",
      },
      {
        nameOnServer: "Prioritaet_10",
        nameLocal: "Prioritaet_10",
        nameScope: "priority",
        type: "integer",
      },
      {
        nameOnServer: "Beschreibung_11",
        nameLocal: "Beschreibung_11",
        nameScope: "description",
        type: "string",
      },
      {
        nameOnServer: "erledigt_17",
        nameLocal: "erledigt_17",
        nameScope: "done",
        type: "boolean",
      },
      {
        nameOnServer: "erledigtAm_18",
        nameLocal: "erledigtAm_18",
        nameScope: "dateDone",
        type: "date",
      },
    ],
  },

  Auftragsstatus: {
    tableOnServer: "auftragsstatus",
    table: "auftragsstatus",
    nameScope: "stateOrder",
    columns: [
      {
        nameOnServer: "Bezeichnung_5",
        nameLocal: "Bezeichnung_5",
        nameScope: "name",
        type: "string",
      },
    ],
  },

  Zahlungsart: {
    tableOnServer: "zahlungsart",
    table: "zahlungsart",
    nameScope: "paymentType",
    columns: [
      {
        nameOnServer: "active",
        nameLocal: "active",
        nameScope: "active",
        type: "boolean",
      },
      {
        nameOnServer: "Bezeichnung_5",
        nameLocal: "Bezeichnung_5",
        nameScope: "name",
        type: "string",
      },
    ],
  },

  Zahlungsziel: {
    tableOnServer: "kondition",
    table: "kondition",
    nameScope: "paymentTarget",
    columns: [
      {
        nameOnServer: "active",
        nameLocal: "active",
        nameScope: "active",
        type: "boolean",
      },
      {
        nameOnServer: "Bezeichnung_5",
        nameLocal: "Bezeichnung_5",
        nameScope: "name",
        type: "string",
      },
    ],
  },

  Preisliste: {
    tableOnServer: "preisliste",
    table: "preisliste",
    nameScope: "pricelist",
    columns: [
      {
        nameOnServer: "active",
        nameLocal: "active",
        nameScope: "active",
        type: "boolean",
      },
      {
        nameOnServer: "Bezeichnung_5",
        nameLocal: "Bezeichnung_5",
        nameScope: "name",
        type: "string",
      },
    ],
  },

  Kontaktart: {
    tableOnServer: "kontaktart",
    table: "kontaktart",
    nameScope: "contactType",
    columns: [
      {
        nameOnServer: "Bezeichnung_5",
        nameLocal: "Bezeichnung_5",
        nameScope: "name",
        type: "string",
      },
    ],
  },

  Stornierungsgrund: {
    tableOnServer: "stornierungsgrund",
    table: "stornierungsgrund",
    nameScope: "cancellationReason",
    columns: [
      {
        nameOnServer: "Bezeichnung_5",
        nameLocal: "Bezeichnung_5",
        nameScope: "name",
        type: "string",
      },
    ],
  },

  Aufgabenkategorie: {
    tableOnServer: "aufgabenkategorie",
    table: "aufgabenkategorie",
    nameScope: "taskCategory",
    columns: [
      {
        nameOnServer: "Bezeichnung_5",
        nameLocal: "Bezeichnung_5",
        nameScope: "name",
        type: "string",
      },
    ],
  },

  Geschlecht: {
    tableOnServer: "geschlecht",
    table: "geschlecht",
    nameScope: "sex",
    columns: [
      {
        nameOnServer: "Kurzanrede_6",
        nameLocal: "Kurzanrede_6",
        nameScope: "formOfAddress",
        type: "string",
      },
    ],
  },

  Land: {
    tableOnServer: "land",
    table: "land",
    nameScope: "country",
    columns: [
      {
        nameOnServer: "Bezeichnung_5",
        nameLocal: "Bezeichnung_5",
        nameScope: "name",
        type: "string",
      },
    ],
  },

  Kundenkategorie: {
    tableOnServer: "kundenkategorie",
    table: "kundenkategorie",
    nameScope: "customerCategory",
    columns: [
      {
        nameOnServer: "Bezeichnung_5",
        nameLocal: "Bezeichnung_5",
        nameScope: "name",
        type: "string",
      },
    ],
  },

  Angebot: {
    tableOnServer: "angebot",
    table: "angebot",
    nameScope: "offer",
    columns: [
      {
        nameOnServer: "Nummer_5",
        nameLocal: "Nummer_5",
        nameScope: "number",
        type: "string",
      },
      {
        nameOnServer: "Datum_6",
        nameLocal: "Datum_6",
        nameScope: "date",
        type: "date",
      },
      {
        nameOnServer: "NN_9999",
        nameLocal: "idCustomerAtClient_9999",
        nameScope: "idCustomerAtClient",
        type: "integer",
      },
      {
        nameOnServer: "Kunde_9",
        nameLocal: "Kunde_9",
        nameScope: "idCustomerAtServer",
        type: "string",
      },
      {
        nameOnServer: "uuidCustomer_9999",
        nameLocal: "uuidCustomer",
        nameScope: "uuidCustomer",
        type: "string",
      },
      {
        nameOnServer: "Name_15",
        nameLocal: "Name_15",
        nameScope: "name",
        type: "string",
      },
      {
        nameOnServer: "Vorname_19",
        nameLocal: "Vorname_19",
        nameScope: "firstName",
        type: "string",
      },
      {
        nameOnServer: "Strasse_21",
        nameLocal: "Strasse_21",
        nameScope: "street",
        type: "string",
      },
      {
        nameOnServer: "PLZ_22",
        nameLocal: "PLZ_22",
        nameScope: "zipCode",
        type: "string",
      },
      {
        nameOnServer: "Ort_23",
        nameLocal: "Ort_23",
        nameScope: "city",
        type: "string",
      },
      {
        nameOnServer: "NN_9999",
        nameLocal: "idOrderAtClient_9999",
        nameScope: "idOrderAtClient",
        type: "integer",
      },
      {
        nameOnServer: "Auftrag_50",
        nameLocal: "Auftrag_50",
        nameScope: "idOrderAtServer",
        type: "integer",
      },
      {
        nameOnServer: "uuidOrder_9999",
        nameLocal: "uuidOrder",
        nameScope: "uuidOrder",
        type: "string",
      },
      {
        nameOnServer: "NN_9999",
        nameLocal: "idPaymentTypeAtClient_9999",
        nameScope: "idPaymentTypeAtClient",
        type: "integer",
      },
      {
        nameOnServer: "Zahlungsart_60",
        nameLocal: "Zahlungsart_60",
        nameScope: "idPaymentTypeAtServer",
        type: "integer",
      },
      {
        nameOnServer: "uuidPaymentType_9999",
        nameLocal: "uuidPaymentType",
        nameScope: "uuidPaymentType",
        type: "string",
      },
      {
        nameOnServer: "Rabatt_68",
        nameLocal: "Rabatt_68",
        nameScope: "discount",
        type: "string",
      },
      {
        nameOnServer: "Ust-befreit_79",
        nameLocal: "Ust_befreit_79",
        nameScope: "taxExempt",
        type: "string",
      },
      {
        nameOnServer: "NN_9999",
        nameLocal: "seqPosIdsAtClient_9999",
        nameScope: "sequenceOfPositionIdsAtClient",
        type: "string",
      },
      {
        nameOnServer: "Positionen_82",
        nameLocal: "Positionen_82",
        nameScope: "sequenceOfPositionIdsAtServer",
        type: "string",
      },
      {
        nameOnServer: "uuidsSalePositions_9999",
        nameLocal: "uuidsSalePositions",
        nameScope: "uuidsSalePositions",
        type: "string",
      },
      {
        nameOnServer: "Positionssumme Netto_83V",
        nameLocal: "PositionssummeNetto_83V",
        nameScope: "sumPosNetV",
        type: "string",
      },
      {
        nameOnServer: "Positionssumme Ust._84V",
        nameLocal: "PositionssummeUst_84V",
        nameScope: "sumPosTaxV",
        type: "string",
      },
      {
        nameOnServer: "Positionssumme Brutto_87V",
        nameLocal: "PositionssummeBrutto_87V",
        nameScope: "sumPosGrossV",
        type: "string",
      },
      {
        nameOnServer: "Summe Netto_93V",
        nameLocal: "SummeNetto_93V",
        nameScope: "sumNetV",
        type: "string",
      },
      {
        nameOnServer: "Summe Ust._94V",
        nameLocal: "SummeUst_94V",
        nameScope: "sumTaxV",
        type: "string",
      },
      {
        nameOnServer: "Summe Brutto_97V",
        nameLocal: "SummeBrutto_97V",
        nameScope: "sumGrossV",
        type: "string",
      },
      {
        nameOnServer: "Einstandspreis_110V",
        nameLocal: "Einstandspreis_110V",
        nameScope: "costPriceTotalV",
        type: "string",
      },
      {
        nameOnServer: "Gesamtkosten_111V",
        nameLocal: "Gesamtkosten_111V",
        nameScope: "purchasePriceTotalV",
        type: "string",
      },
      {
        nameOnServer: "DB 1_112V",
        nameLocal: "DB1_112V",
        nameScope: "db1V",
        type: "string",
      },
      {
        nameOnServer: "DB 2_113V",
        nameLocal: "DB2_113V",
        nameScope: "db2V",
        type: "string",
      },
      {
        nameOnServer: "Anteil DB 1_114",
        nameLocal: "AnteilDB1_114",
        nameScope: "db1Perc",
        type: "string",
      },
      {
        nameOnServer: "Anteil DB 2_115",
        nameLocal: "AnteilDB2_115",
        nameScope: "db2Perc",
        type: "string",
      },
      // { not used
      //	nameOnServer: 'Summenrabatt_116V',
      //	nameLocal: 'Summenrabatt_116V',
      //	nameScope: 'sumDiscountV',
      //	type: 'number'
      //},
      {
        nameOnServer: "Gewicht kg (Brutto)_117",
        nameLocal: "GewichtKgBrutto_117",
        nameScope: "weightKg",
        type: "number",
      },
      {
        nameOnServer: "DummyDate1_171",
        nameLocal: "EnquiryDate_171",
        nameScope: "enquiryDate",
        type: "date",
      },
      {
        nameOnServer: "NN_9999",
        nameLocal: "idCancellationReasonAtClient_9999",
        nameScope: "idCancellationReasonAtClient",
        type: "integer",
      },
      {
        nameOnServer: "Stornierungsgrund_149",
        nameLocal: "Stornierungsgrund_149",
        nameScope: "idCancellationReasonAtServer",
        type: "integer",
      },
      {
        nameOnServer: "uuidCancellationReason_9999",
        nameLocal: "uuidCancellationReason",
        nameScope: "uuidCancellationReason",
        type: "string",
      },
      //{
      //	nameOnServer: '',
      //	nameLocal: 'UnterschriftArray_9999',
      //	nameScope: 'signatureArray',
      //	type: 'boolean'
      //},
      {
        nameOnServer: "NN_0000",
        nameLocal: "UnterschriftBild_0000", // 0000 => special treatment at the server
        nameScope: "signatureImage",
        type: "string",
      },
      {
        nameOnServer: "Bilder_277",
        nameLocal: "",
        nameScope: "idsPicturesAtServer",
        type: "string",
      },
    ],
  },

  Angebotspositionen: {
    tableOnServer: "angebotspositionen",
    table: "angebotspositionen",
    nameScope: "offerPosition",
    columns: [
      {
        nameOnServer: "idParentAtClient_9999", // id parent at client
        nameLocal: "idParentAtClient_9999", // 9999 => not synchronized
        nameScope: "idParentAtClient",
        type: "string",
      },
      {
        nameOnServer: "parent_link", // id parent at server
        nameLocal: "parent_link",
        nameScope: "idParentAtServer",
        type: "string",
      },
      {
        nameOnServer: "uuidParent_9999",
        nameLocal: "uuidParent",
        nameScope: "uuidParent",
        type: "string",
      },
      {
        nameOnServer: "Positionstyp_3",
        nameLocal: "Positionstyp_3",
        nameScope: "positionType",
        type: "string",
      },
      {
        nameOnServer: "Artikel_4",
        nameLocal: "Artikel_4",
        nameScope: "idArticleAtServer",
        type: "number",
      },
      {
        nameOnServer: "idArticleAtClient_9999", // 9999 => not synchronized
        nameLocal: "idArticleAtClient_9999",
        nameScope: "idArticleAtClient",
        type: "number",
      },
      {
        nameOnServer: "uuidArticle_9999",
        nameLocal: "uuidArticle",
        nameScope: "uuidArticle",
        type: "string",
      },
      {
        nameOnServer: "BezeichnungText_5",
        nameLocal: "BezeichnungText_5",
        nameScope: "name",
        type: "string",
      },
      {
        nameOnServer: "Menge_6",
        nameLocal: "Menge_6",
        nameScope: "amount",
        type: "string",
      },
      {
        nameOnServer: "EinzelpreisNetto_10V",
        nameLocal: "EinzelpreisNetto_10V",
        nameScope: "priceUnitNetV",
        type: "string",
      },
      {
        nameOnServer: "Nettosumme Artikel_11V",
        nameLocal: "NettosummeArtikel_11V",
        nameScope: "sumArticleNetV",
        type: "string",
      },
      {
        nameOnServer: "Steuersatz_12",
        nameLocal: "Steuersatz_12",
        nameScope: "idTaxRateAtServer",
        type: "number",
      },
      {
        nameOnServer: "uuidTaxRate_9999",
        nameLocal: "uuidTaxRate",
        nameScope: "uuidTaxRate",
        type: "string",
      },
      {
        nameOnServer: "Umsatzsteuer Gesamt_13V",
        nameLocal: "UmsatzsteuerGesamt_13V",
        nameScope: "taxSumV",
        type: "string",
      },
      {
        nameOnServer: "Bruttosumme Gesamt_16V",
        nameLocal: "BruttosummeGesamt_16V",
        nameScope: "priceSumGrossV",
        type: "string",
      },
      {
        nameOnServer: "Nettosumme Gesamt_17V",
        nameLocal: "NettosummeGesamt_17V",
        nameScope: "priceSumNetV",
        type: "string",
      },
      {
        nameOnServer: "Beschreibung 1_19",
        nameLocal: "Beschreibung1_19",
        nameScope: "description",
        type: "string",
      },
      {
        nameOnServer: "Beschreibung 2_20",
        nameLocal: "Beschreibung2_20",
        nameScope: "description2",
        type: "string",
      },
      {
        nameOnServer: "Gewicht kg (Brutto)_25",
        nameLocal: "GewichtKgBrutto_25",
        nameScope: "weightKgTotal",
        type: "string",
      },
      {
        nameOnServer: "Gesamt Einstandspreis_27V",
        nameLocal: "GesamtEinstandspreis_27V",
        nameScope: "costPriceTotalV",
        type: "string",
      },
      {
        nameOnServer: "Gesamtkosten_28V",
        nameLocal: "Gesamtkosten_28V",
        nameScope: "purchasePriceTotalV",
        type: "string",
      },
      {
        nameOnServer: "DB 1_29V",
        nameLocal: "DB1_29V",
        nameScope: "db1V",
        type: "string",
      },
      {
        nameOnServer: "DB 2_30V",
        nameLocal: "DB2_30V",
        nameScope: "db2V",
        type: "string",
      },
      {
        nameOnServer: "Einzel Einkaufspreis_32V",
        nameLocal: "EinzelEinkaufspreis_32V",
        nameScope: "purchasePriceUnitV",
        type: "string",
      },
      {
        nameOnServer: "Provisionsstufe_37",
        nameLocal: "Provisionsstufe_37",
        nameScope: "commissionLevel",
        type: "string",
      },
      {
        nameOnServer: "Artikelgruppe_41",
        nameLocal: "Artikelgruppe_41",
        nameScope: "articleGroupId",
        type: "string",
      },
      {
        nameOnServer: "Rabatt Artikel_42",
        nameLocal: "RabattArtikel_42",
        nameScope: "discount",
        type: "number",
      },
      {
        nameOnServer: "DB %_73",
        nameLocal: "DB2Percent_73",
        nameScope: "db2Percent",
        type: "number",
      },
      {
        nameOnServer: "Einzelpreis Brutto_88V",
        nameLocal: "EinzelpreisBrutto_88V",
        nameScope: "priceUnitGrossV",
        type: "number",
      },
      {
        nameOnServer: "GewichtKgEinheit_9999", // 9999 => not synchronized
        nameLocal: "GewichtKgEinheit_9999",
        nameScope: "weightKgUnit",
        type: "string",
      },
      {
        nameOnServer: "Gesamtsteuersatz_9999", // 9999 => not synchronized
        nameLocal: "Gesamtsteuersatz_9999",
        nameScope: "taxFactor",
        type: "string",
      },
      {
        nameOnServer: "EinzelEinstandspreis_9999V", // 9999 => not synchronized
        nameLocal: "EinzelEinstandspreis_9999V",
        nameScope: "costPriceUnitV",
        type: "string",
      },
    ],
  },

  Auftrag: {
    tableOnServer: "auftrag",
    table: "auftrag",
    nameScope: "order",
    columns: [
      {
        nameOnServer: "Nummer_5",
        nameLocal: "Nummer_5",
        nameScope: "number",
        type: "string",
      },
      {
        nameOnServer: "Datum_6",
        nameLocal: "Datum_6",
        nameScope: "date",
        type: "date",
      },
      {
        nameOnServer: "NN_9999",
        nameLocal: "idCustomerAtClient_9999",
        nameScope: "idCustomerAtClient",
        type: "integer",
      },
      {
        nameOnServer: "Kunde_9",
        nameLocal: "Kunde_9",
        nameScope: "idCustomerAtServer",
        type: "string",
      },
      {
        nameOnServer: "uuidCustomer_9999",
        nameLocal: "uuidCustomer",
        nameScope: "uuidCustomer",
        type: "string",
      },
      {
        nameOnServer: "Name_15",
        nameLocal: "Name_15",
        nameScope: "name",
        type: "string",
      },
      {
        nameOnServer: "Vorname_19",
        nameLocal: "Vorname_19",
        nameScope: "firstName",
        type: "string",
      },
      {
        nameOnServer: "Strasse_21",
        nameLocal: "Strasse_21",
        nameScope: "street",
        type: "string",
      },
      {
        nameOnServer: "PLZ_22",
        nameLocal: "PLZ_22",
        nameScope: "zipCode",
        type: "string",
      },
      {
        nameOnServer: "Ort_23",
        nameLocal: "Ort_23",
        nameScope: "city",
        type: "string",
      },
      {
        nameOnServer: "NN_9999",
        nameLocal: "idOfferAtClient_9999",
        nameScope: "idOfferAtClient",
        type: "string",
      },
      {
        nameOnServer: "Angebot_49",
        nameLocal: "Angebot_49",
        nameScope: "idOfferAtServer",
        type: "integer",
      },
      {
        nameOnServer: "uuidOffer_9999",
        nameLocal: "uuidOffer",
        nameScope: "uuidOffer",
        type: "string",
      },
      {
        nameOnServer: "NN_9999",
        nameLocal: "idInvoiceAtClient_9999",
        nameScope: "idInvoiceAtClient",
        type: "string",
      },
      {
        nameOnServer: "Rechnung_52",
        nameLocal: "Rechnung_52",
        nameScope: "idInvoiceAtServer",
        type: "integer",
      },
      {
        nameOnServer: "uuidInvoice_9999",
        nameLocal: "uuidInvoice",
        nameScope: "uuidInvoice",
        type: "string",
      },
      {
        nameOnServer: "NN_9999",
        nameLocal: "idPaymentTypeAtClient_9999",
        nameScope: "idPaymentTypeAtClient",
        type: "integer",
      },
      {
        nameOnServer: "Zahlungsart_60",
        nameLocal: "Zahlungsart_60",
        nameScope: "idPaymentTypeAtServer",
        type: "integer",
      },
      {
        nameOnServer: "uuidPaymentType_9999",
        nameLocal: "uuidPaymentType",
        nameScope: "uuidPaymentType",
        type: "string",
      },
      {
        nameOnServer: "Rabatt_68",
        nameLocal: "Rabatt_68",
        nameScope: "discount",
        type: "string",
      },
      {
        nameOnServer: "Ust-befreit_79",
        nameLocal: "Ust_befreit_79",
        nameScope: "taxExempt",
        type: "string",
      },
      {
        nameOnServer: "NN_9999",
        nameLocal: "seqPosIdsAtClient_9999",
        nameScope: "sequenceOfPositionIdsAtClient",
        type: "string",
      },
      {
        nameOnServer: "Positionen_82",
        nameLocal: "Positionen_82",
        nameScope: "sequenceOfPositionIdsAtServer",
        type: "string",
      },
      {
        nameOnServer: "uuidsSalePositions_9999",
        nameLocal: "uuidsSalePositions",
        nameScope: "uuidsSalePositions",
        type: "string",
      },
      {
        nameOnServer: "Positionssumme Netto_83V",
        nameLocal: "PositionssummeNetto_83V",
        nameScope: "sumPosNetV",
        type: "string",
      },
      {
        nameOnServer: "Positionssumme Ust._84V",
        nameLocal: "PositionssummeUst_84V",
        nameScope: "sumPosTaxV",
        type: "string",
      },
      {
        nameOnServer: "Positionssumme Brutto_87V",
        nameLocal: "PositionssummeBrutto_87V",
        nameScope: "sumPosGrossV",
        type: "string",
      },
      {
        nameOnServer: "Summe Netto_93V",
        nameLocal: "SummeNetto_93V",
        nameScope: "sumNetV",
        type: "string",
      },
      {
        nameOnServer: "Summe Ust._94V",
        nameLocal: "SummeUst_94V",
        nameScope: "sumTaxV",
        type: "string",
      },
      {
        nameOnServer: "Summe Brutto_97V",
        nameLocal: "SummeBrutto_97V",
        nameScope: "sumGrossV",
        type: "string",
      },
      {
        nameOnServer: "Einstandspreis_110V",
        nameLocal: "Einstandspreis_110V",
        nameScope: "costPriceTotalV",
        type: "string",
      },
      {
        nameOnServer: "Gesamtkosten_111V",
        nameLocal: "Gesamtkosten_111V",
        nameScope: "purchasePriceTotalV",
        type: "string",
      },
      {
        nameOnServer: "DB 1_112V",
        nameLocal: "DB1_112V",
        nameScope: "db1V",
        type: "string",
      },
      {
        nameOnServer: "DB 2_113V",
        nameLocal: "DB2_113V",
        nameScope: "db2V",
        type: "string",
      },
      {
        nameOnServer: "Anteil DB 1_114",
        nameLocal: "AnteilDB1_114",
        nameScope: "db1Perc",
        type: "string",
      },
      {
        nameOnServer: "Anteil DB 2_115",
        nameLocal: "AnteilDB2_115",
        nameScope: "db2Perc",
        type: "string",
      },
      // {
      //	nameOnServer: 'Summenrabatt_116V',
      //	nameLocal: 'Summenrabatt_116V',
      //	nameScope: 'sumDiscountV',
      //	type: 'number'
      //},
      {
        nameOnServer: "Gewicht kg_117",
        nameLocal: "GewichtKg_117",
        nameScope: "weightKg",
        type: "number",
      },
      {
        nameOnServer: "NN_9999",
        nameLocal: "idCancellationReasonAtClient_9999",
        nameScope: "idCancellationReasonAtClient",
        type: "integer",
      },
      {
        nameOnServer: "Stornierungsgrund_149",
        nameLocal: "Stornierungsgrund_149",
        nameScope: "idCancellationReasonAtServer",
        type: "integer",
      },
      {
        nameOnServer: "uuidCancellationReason_9999",
        nameLocal: "uuidCancellationReason",
        nameScope: "uuidCancellationReason",
        type: "string",
      },
      {
        nameOnServer: "NN_9999",
        nameLocal: "idStateOrderAtClient_9999",
        nameScope: "idStateOrderAtClient",
        type: "integer",
      },
      {
        nameOnServer: "gebucht_154",
        nameLocal: "gebucht_154",
        nameScope: "booked",
        type: "boolean",
      },
      {
        nameOnServer: "Status_159",
        nameLocal: "Status_159",
        nameScope: "idStateOrderAtServer",
        type: "integer",
      },
      {
        nameOnServer: "uuidStateOrder_9999",
        nameLocal: "uuidStateOrder",
        nameScope: "uuidStateOrder",
        type: "string",
      },
      //{
      //	nameOnServer: '',
      //	nameLocal: 'UnterschriftArray_9999',
      //	nameScope: 'signatureArray',
      //	type: 'boolean'
      //},
      {
        nameOnServer: "NN_9999",
        nameLocal: "UnterschriftBild_0000", // 0000 => special treatment at the server
        nameScope: "signatureImage",
        type: "string",
      },
      {
        nameOnServer: "Bilder_136",
        nameLocal: "",
        nameScope: "idsPicturesAtServer",
        type: "string",
      },
    ],
  },

  Auftragspositionen: {
    tableOnServer: "auftragspositionen",
    table: "auftragspositionen",
    nameScope: "orderPosition",
    columns: [
      {
        nameOnServer: "parent_link",
        nameLocal: "parent_link",
        nameScope: "idParentAtServer",
        type: "string",
      },
      {
        nameOnServer: "idParentAtClient_9999", // 9999 => not synchronized
        nameLocal: "idParentAtClient_9999",
        nameScope: "idParentAtClient",
        type: "string",
      },
      {
        nameOnServer: "uuidParent_9999",
        nameLocal: "uuidParent",
        nameScope: "uuidParent",
        type: "string",
      },
      {
        nameOnServer: "Positionstyp_3",
        nameLocal: "Positionstyp_3",
        nameScope: "positionType",
        type: "string",
      },
      {
        nameOnServer: "Artikel_4",
        nameLocal: "Artikel_4",
        nameScope: "idArticleAtServer",
        type: "number",
      },
      {
        nameOnServer: "idArticleAtClient_9999", // 9999 => not synchronized
        nameLocal: "idArticleAtClient_9999",
        nameScope: "idArticleAtClient",
        type: "number",
      },
      {
        nameOnServer: "uuidArticle_9999",
        nameLocal: "uuidArticle",
        nameScope: "uuidArticle",
        type: "string",
      },
      {
        nameOnServer: "BezeichnungText_5",
        nameLocal: "BezeichnungText_5",
        nameScope: "name",
        type: "string",
      },
      {
        nameOnServer: "Menge_6",
        nameLocal: "Menge_6",
        nameScope: "amount",
        type: "string",
      },
      {
        nameOnServer: "EinzelpreisNetto_10V",
        nameLocal: "EinzelpreisNetto_10V",
        nameScope: "priceUnitNetV",
        type: "string",
      },
      {
        nameOnServer: "Nettosumme Artikel_11V",
        nameLocal: "NettosummeArtikel_11V",
        nameScope: "sumArticleNetV",
        type: "string",
      },
      {
        nameOnServer: "Steuersatz_12",
        nameLocal: "Steuersatz_12",
        nameScope: "idTaxRateAtServer",
        type: "number",
      },
      {
        nameOnServer: "uuidTaxRate_9999",
        nameLocal: "uuidTaxRate",
        nameScope: "uuidTaxRate",
        type: "string",
      },
      {
        nameOnServer: "Umsatzsteuer Gesamt_13V",
        nameLocal: "UmsatzsteuerGesamt_13V",
        nameScope: "taxSumV",
        type: "string",
      },
      {
        nameOnServer: "Bruttosumme Gesamt_16V",
        nameLocal: "BruttosummeGesamt_16V",
        nameScope: "priceSumGrossV",
        type: "string",
      },
      {
        nameOnServer: "Nettosumme Gesamt_17V",
        nameLocal: "NettosummeGesamt_17V",
        nameScope: "priceSumNetV",
        type: "string",
      },
      {
        nameOnServer: "Beschreibung 1_19",
        nameLocal: "Beschreibung1_19",
        nameScope: "description",
        type: "string",
      },
      {
        nameOnServer: "Beschreibung 2_20",
        nameLocal: "Beschreibung2_20",
        nameScope: "description2",
        type: "string",
      },
      {
        nameOnServer: "Gewicht kg_25",
        nameLocal: "GewichtKg_25",
        nameScope: "weightKgTotal",
        type: "string",
      },
      {
        nameOnServer: "Gesamt Einstandspreis_27V",
        nameLocal: "GesamtEinstandspreis_27V",
        nameScope: "costPriceTotalV",
        type: "string",
      },
      {
        nameOnServer: "Gesamtkosten_28V",
        nameLocal: "Gesamtkosten_28V",
        nameScope: "purchasePriceTotalV",
        type: "string",
      },
      {
        nameOnServer: "DB 1_29V",
        nameLocal: "DB1_29V",
        nameScope: "db1V",
        type: "string",
      },
      {
        nameOnServer: "DB 2_30V",
        nameLocal: "DB2_30V",
        nameScope: "db2V",
        type: "string",
      },
      {
        nameOnServer: "Einzel Einkaufspreis_32V",
        nameLocal: "EinzelEinkaufspreis_32V",
        nameScope: "purchasePriceUnitV",
        type: "string",
      },
      {
        nameOnServer: "Provisionsstufe_37",
        nameLocal: "Provisionsstufe_37",
        nameScope: "commissionLevel",
        type: "string",
      },
      {
        nameOnServer: "Artikelgruppe_41",
        nameLocal: "Artikelgruppe_41",
        nameScope: "articleGroupId",
        type: "string",
      },
      {
        nameOnServer: "Rabatt Artikel_42",
        nameLocal: "RabattArtikel_42",
        nameScope: "discount",
        type: "number",
      },
      {
        nameOnServer: "DB %_73",
        nameLocal: "DB2Percent_73",
        nameScope: "db2Percent",
        type: "number",
      },
      {
        nameOnServer: "Einzelpreis Brutto_88V",
        nameLocal: "EinzelpreisBrutto_88V",
        nameScope: "priceUnitGrossV",
        type: "number",
      },
      {
        nameOnServer: "GewichtKgEinheit_9999", // 9999 => not synchronized
        nameLocal: "GewichtKgEinheit_9999",
        nameScope: "weightKgUnit",
        type: "string",
      },
      {
        nameOnServer: "Gesamtsteuersatz_9999", // 9999 => not synchronized
        nameLocal: "Gesamtsteuersatz_9999",
        nameScope: "taxFactor",
        type: "string",
      },
      {
        nameOnServer: "EinzelEinstandspreis_9999V", // 9999 => not synchronized
        nameLocal: "EinzelEinstandspreis_9999V",
        nameScope: "costPriceUnitV",
        type: "string",
      },
    ],
  },

  Lieferung: {
    tableOnServer: "lieferung",
    table: "lieferung",
    nameScope: "delivery",
    columns: [],
  },

  Lieferpositionen: {
    tableOnServer: "lieferpositionen",
    table: "lieferpositionen",
    nameScope: "deliveryPosition",
    columns: [],
  },

  Rechnung: {
    tableOnServer: "rechnung",
    table: "rechnung",
    nameScope: "invoice",
    columns: [
      {
        nameOnServer: "Nummer_5",
        nameLocal: "Nummer_5",
        nameScope: "number",
        type: "string",
      },
      {
        nameOnServer: "Datum_6",
        nameLocal: "Datum_6",
        nameScope: "date",
        type: "date",
      },
      {
        nameOnServer: "NN_9999",
        nameLocal: "idCustomerAtClient_9999",
        nameScope: "idCustomerAtClient",
        type: "integer",
      },
      {
        nameOnServer: "Kunde_9",
        nameLocal: "Kunde_9",
        nameScope: "idCustomerAtServer",
        type: "string",
      },
      {
        nameOnServer: "uuidCustomer_9999",
        nameLocal: "uuidCustomer",
        nameScope: "uuidCustomer",
        type: "string",
      },

      {
        nameOnServer: "Name_15",
        nameLocal: "Name_15",
        nameScope: "name",
        type: "string",
      },
      {
        nameOnServer: "Vorname_19",
        nameLocal: "Vorname_19",
        nameScope: "firstName",
        type: "string",
      },
      {
        nameOnServer: "Strasse_21",
        nameLocal: "Strasse_21",
        nameScope: "street",
        type: "string",
      },
      {
        nameOnServer: "PLZ_22",
        nameLocal: "PLZ_22",
        nameScope: "zipCode",
        type: "string",
      },
      {
        nameOnServer: "Ort_23",
        nameLocal: "Ort_23",
        nameScope: "city",
        type: "string",
      },
      {
        nameOnServer: "NN_9999",
        nameLocal: "idOrderAtClient_9999",
        nameScope: "idOrderAtClient",
        type: "string",
      },
      {
        nameOnServer: "Auftrag_50",
        nameLocal: "Auftrag_50",
        nameScope: "idOrderAtServer",
        type: "integer",
      },
      {
        nameOnServer: "uuidOrder_9999",
        nameLocal: "uuidOrder",
        nameScope: "uuidOrder",
        type: "string",
      },
      {
        nameOnServer: "NN_9999",
        nameLocal: "idPaymentTypeAtClient_9999",
        nameScope: "idPaymentTypeAtClient",
        type: "integer",
      },
      {
        nameOnServer: "Zahlungsart_60",
        nameLocal: "Zahlungsart_60",
        nameScope: "idPaymentTypeAtServer",
        type: "integer",
      },
      {
        nameOnServer: "uuidPaymentType_9999",
        nameLocal: "uuidPaymentType",
        nameScope: "uuidPaymentType",
        type: "string",
      },
      {
        nameOnServer: "Rabatt_68",
        nameLocal: "Rabatt_68",
        nameScope: "discount",
        type: "string",
      },
      {
        nameOnServer: "Ust-befreit_79",
        nameLocal: "Ust_befreit_79",
        nameScope: "taxExempt",
        type: "string",
      },
      {
        nameOnServer: "NN_9999",
        nameLocal: "seqPosIdsAtClient_9999",
        nameScope: "sequenceOfPositionIdsAtClient",
        type: "string",
      },
      {
        nameOnServer: "Positionen_82",
        nameLocal: "Positionen_82",
        nameScope: "sequenceOfPositionIdsAtServer",
        type: "string",
      },
      {
        nameOnServer: "uuidsSalePositions_9999",
        nameLocal: "uuidsSalePositions",
        nameScope: "uuidsSalePositions",
        type: "string",
      },
      {
        nameOnServer: "Positionssumme Netto_83V",
        nameLocal: "PositionssummeNetto_83V",
        nameScope: "sumPosNetV",
        type: "string",
      },
      {
        nameOnServer: "Positionssumme Ust._84V",
        nameLocal: "PositionssummeUst_84V",
        nameScope: "sumPosTaxV",
        type: "string",
      },
      {
        nameOnServer: "Positionssumme Brutto_87V",
        nameLocal: "PositionssummeBrutto_87V",
        nameScope: "sumPosGrossV",
        type: "string",
      },
      {
        nameOnServer: "Summe Netto_93V",
        nameLocal: "SummeNetto_93V",
        nameScope: "sumNetV",
        type: "string",
      },
      {
        nameOnServer: "Summe Ust._94V",
        nameLocal: "SummeUst_94V",
        nameScope: "sumTaxV",
        type: "string",
      },
      {
        nameOnServer: "Summe Brutto_97V",
        nameLocal: "SummeBrutto_97V",
        nameScope: "sumGrossV",
        type: "string",
      },
      {
        nameOnServer: "Einstandspreis_110V",
        nameLocal: "Einstandspreis_110V",
        nameScope: "costPriceTotalV",
        type: "string",
      },
      {
        nameOnServer: "Gesamtkosten_111V",
        nameLocal: "Gesamtkosten_111V",
        nameScope: "purchasePriceTotalV",
        type: "string",
      },
      {
        nameOnServer: "DB 1_112V",
        nameLocal: "DB1_112V",
        nameScope: "db1V",
        type: "string",
      },
      {
        nameOnServer: "DB 2_113V",
        nameLocal: "DB2_113V",
        nameScope: "db2V",
        type: "string",
      },
      {
        nameOnServer: "Anteil DB 1_114",
        nameLocal: "AnteilDB1_114",
        nameScope: "db1Perc",
        type: "string",
      },
      {
        nameOnServer: "Anteil DB 2_115",
        nameLocal: "AnteilDB2_115",
        nameScope: "db2Perc",
        type: "string",
      },
      // {
      //	nameOnServer: 'Summenrabatt_116V',
      //	nameLocal: 'Summenrabatt_116V',
      //	nameScope: 'sumDiscountV',
      //	type: 'number'
      //},
      {
        nameOnServer: "Gewicht kg_117",
        nameLocal: "GewichtKg_117",
        nameScope: "weightKg",
        type: "number",
      },
      {
        nameOnServer: "NN_9999",
        nameLocal: "idCancellationReasonAtClient_9999",
        nameScope: "idCancellationReasonAtClient",
        type: "integer",
      },
      {
        nameOnServer: "Stornierungsgrund_149",
        nameLocal: "Stornierungsgrund_149",
        nameScope: "idCancellationReasonAtServer",
        type: "integer",
      },
      {
        nameOnServer: "uuidCancellationReason_9999",
        nameLocal: "uuidCancellationReason",
        nameScope: "uuidCancellationReason",
        type: "string",
      },
      {
      	nameOnServer: '',
      	nameLocal: 'UnterschriftBild_0000',	// 0000 => special treatment at the server
      	nameScope: 'signatureImage',
      	type: 'string'
      },
    ],
  },

  Rechnungspositionen: {
    tableOnServer: "rechnungspositionen",
    table: "rechnungspositionen",
    nameScope: "invoicePosition",
    columns: [
      {
        nameOnServer: "parent_link",
        nameLocal: "parent_link",
        nameScope: "idParentAtServer",
        type: "string",
      },
      {
        nameOnServer: "idParentAtClient_9999", // 9999 => not synchronized
        nameLocal: "idParentAtClient_9999",
        nameScope: "idParentAtClient",
        type: "string",
      },
      {
        nameOnServer: "uuidParent_9999",
        nameLocal: "uuidParent",
        nameScope: "uuidParent",
        type: "string",
      },
      {
        nameOnServer: "Positionstyp_3",
        nameLocal: "Positionstyp_3",
        nameScope: "positionType",
        type: "string",
      },
      {
        nameOnServer: "Artikel_4",
        nameLocal: "Artikel_4",
        nameScope: "idArticleAtServer",
        type: "number",
      },
      {
        nameOnServer: "idArticleAtClient_9999", // 9999 => not synchronized
        nameLocal: "idArticleAtClient_9999",
        nameScope: "idArticleAtClient",
        type: "number",
      },
      {
        nameOnServer: "uuidArticle_9999",
        nameLocal: "uuidArticle",
        nameScope: "uuidArticle",
        type: "string",
      },
      {
        nameOnServer: "BezeichnungText_5",
        nameLocal: "BezeichnungText_5",
        nameScope: "name",
        type: "string",
      },
      {
        nameOnServer: "Menge_6",
        nameLocal: "Menge_6",
        nameScope: "amount",
        type: "string",
      },
      {
        nameOnServer: "EinzelpreisNetto_10V",
        nameLocal: "EinzelpreisNetto_10V",
        nameScope: "priceUnitNetV",
        type: "string",
      },
      {
        nameOnServer: "Nettosumme Artikel_11V",
        nameLocal: "NettosummeArtikel_11V",
        nameScope: "sumArticleNetV",
        type: "string",
      },
      {
        nameOnServer: "Steuersatz_12",
        nameLocal: "Steuersatz_12",
        nameScope: "idTaxRateAtServer",
        type: "number",
      },
      {
        nameOnServer: "uuidTaxRate_9999",
        nameLocal: "uuidTaxRate",
        nameScope: "uuidTaxRate",
        type: "string",
      },
      {
        nameOnServer: "Umsatzsteuer Gesamt_13V",
        nameLocal: "UmsatzsteuerGesamt_13V",
        nameScope: "taxSumV",
        type: "string",
      },
      {
        nameOnServer: "Bruttosumme Gesamt_16V",
        nameLocal: "BruttosummeGesamt_16V",
        nameScope: "priceSumGrossV",
        type: "string",
      },
      {
        nameOnServer: "Nettosumme Gesamt_17V",
        nameLocal: "NettosummeGesamt_17V",
        nameScope: "priceSumNetV",
        type: "string",
      },
      {
        nameOnServer: "Beschreibung 1_19",
        nameLocal: "Beschreibung1_19",
        nameScope: "description",
        type: "string",
      },
      {
        nameOnServer: "Beschreibung 2_20",
        nameLocal: "Beschreibung2_20",
        nameScope: "description2",
        type: "string",
      },
      {
        nameOnServer: "Gewicht kg_25",
        nameLocal: "GewichtKg_25",
        nameScope: "weightKgTotal",
        type: "string",
      },
      {
        nameOnServer: "Gesamt Einstandspreis_27V",
        nameLocal: "GesamtEinstandspreis_27V",
        nameScope: "costPriceTotalV",
        type: "string",
      },
      {
        nameOnServer: "Gesamtkosten_28V",
        nameLocal: "Gesamtkosten_28V",
        nameScope: "purchasePriceTotalV",
        type: "string",
      },
      {
        nameOnServer: "DB 1_29V",
        nameLocal: "DB1_29V",
        nameScope: "db1V",
        type: "string",
      },
      {
        nameOnServer: "DB 2_30V",
        nameLocal: "DB2_30V",
        nameScope: "db2V",
        type: "string",
      },
      {
        nameOnServer: "Einzel Einkaufspreis_32V",
        nameLocal: "EinzelEinkaufspreis_32V",
        nameScope: "purchasePriceUnitV",
        type: "string",
      },
      {
        nameOnServer: "Provisionsstufe_37",
        nameLocal: "Provisionsstufe_37",
        nameScope: "commissionLevel",
        type: "string",
      },
      {
        nameOnServer: "Artikelgruppe_41",
        nameLocal: "Artikelgruppe_41",
        nameScope: "articleGroupId",
        type: "string",
      },
      {
        nameOnServer: "Rabatt Artikel_42",
        nameLocal: "RabattArtikel_42",
        nameScope: "discount",
        type: "number",
      },
      {
        nameOnServer: "DB %_73",
        nameLocal: "DB2Percent_73",
        nameScope: "db2Percent",
        type: "number",
      },
      {
        nameOnServer: "Einzelpreis Brutto_88V",
        nameLocal: "EinzelpreisBrutto_88V",
        nameScope: "priceUnitGrossV",
        type: "number",
      },
      {
        nameOnServer: "GewichtKgEinheit_9999", // 9999 => not synchronized
        nameLocal: "GewichtKgEinheit_9999",
        nameScope: "weightKgUnit",
        type: "string",
      },
      {
        nameOnServer: "Gesamtsteuersatz_9999", // 9999 => not synchronized
        nameLocal: "Gesamtsteuersatz_9999",
        nameScope: "taxFactor",
        type: "string",
      },
      {
        nameOnServer: "EinzelEinstandspreis_9999V", // 9999 => not synchronized
        nameLocal: "EinzelEinstandspreis_9999V",
        nameScope: "costPriceUnitV",
        type: "string",
      },
    ],
  },

  // Kundenkontakt
  Kundenkontakt: {
    tableOnServer: "kundenkontakt",
    table: "kundenkontakt",
    nameScope: "customerContact",
    columns: [
      {
        nameOnServer: "NN_9999",
        nameLocal: "idCustomerAtClient_9999",
        nameScope: "idCustomerAtClient",
        type: "string",
      },
      {
        nameOnServer: "Kunde_6",
        nameLocal: "Kunde_6",
        nameScope: "idCustomerAtServer",
        type: "string",
      },
      {
        nameOnServer: "uuidCustomer_9999",
        nameLocal: "uuidCustomer",
        nameScope: "uuidCustomer",
        type: "string",
      },
      {
        nameOnServer: "Datum_5",
        nameLocal: "Datum_5",
        nameScope: "date",
        type: "date",
      },
      {
        nameOnServer: "NN_9999",
        nameLocal: "idContactTypeAtClient_9999", // 9999 => not synchronized
        nameScope: "idContactTypeAtClient",
        type: "string",
      },
      {
        nameOnServer: "Kontaktart_7",
        nameLocal: "Kontaktart_7",
        nameScope: "idContactTypeAtServer",
        type: "string",
      },
      {
        nameOnServer: "uuidContactType_9999",
        nameLocal: "uuidContactType",
        nameScope: "uuidContactType",
        type: "string",
      },
      {
        nameOnServer: "Thema_16",
        nameLocal: "Thema_16",
        nameScope: "subject",
        type: "string",
      },
      {
        nameOnServer: "Zusagen_17",
        nameLocal: "Zusagen_17",
        nameScope: "assurances",
        type: "string",
      },
      {
        nameOnServer: "ZuTun_18",
        nameLocal: "ZuTun_18",
        nameScope: "actions",
        type: "string",
      },
    ],
  },

  Ansprechpartner: {
    tableOnServer: "ansprechpartner",
    table: "ansprechpartner",
    nameScope: "partner",
    columns: [
      {
        nameOnServer: "NN_9999",
        nameLocal: "idCustomerAtClient_9999",
        nameScope: "idCustomerAtClient",
        type: "string",
      },
      {
        nameOnServer: "Kunde_28",
        nameLocal: "Kunde_28",
        nameScope: "idCustomerAtServer",
        type: "string",
      },
      {
        nameOnServer: "uuidCustomer_9999",
        nameLocal: "uuidCustomer",
        nameScope: "uuidCustomer",
        type: "string",
      },
      {
        nameOnServer: "NN_9999",
        nameLocal: "idSexAtClient_9999", // 9999 => not synchronized
        nameScope: "idSexAtClient",
        type: "string",
      },
      {
        nameOnServer: "Geschlecht_8",
        nameLocal: "Geschlecht_8",
        nameScope: "idSexAtServer",
        type: "string",
      },
      {
        nameOnServer: "uuidSex_9999",
        nameLocal: "uuidSex",
        nameScope: "uuidSex",
        type: "string",
      },
      {
        nameOnServer: "Titel_7",
        nameLocal: "Titel_7",
        nameScope: "academicTitle",
        type: "string",
      },
      {
        nameOnServer: "Name_5",
        nameLocal: "Name_5",
        nameScope: "lastName",
        type: "string",
      },
      {
        nameOnServer: "Vorname_6",
        nameLocal: "Vorname_6",
        nameScope: "firstName",
        type: "string",
      },
      {
        nameOnServer: "Position_9",
        nameLocal: "Position_9",
        nameScope: "position",
        type: "string",
      },
      {
        nameOnServer: "Tel1_10",
        nameLocal: "Tel1_10",
        nameScope: "telephone1",
        type: "string",
      },
      {
        nameOnServer: "Tel2_11",
        nameLocal: "Tel2_11",
        nameScope: "telephone2",
        type: "string",
      },
      {
        nameOnServer: "Fax_12",
        nameLocal: "Fax_12",
        nameScope: "fax",
        type: "string",
      },
      {
        nameOnServer: "EMail_13",
        nameLocal: "EMail_13",
        nameScope: "email",
        type: "string",
      },
      {
        nameOnServer: "Bemerkung_15",
        nameLocal: "Bemerkung_15",
        nameScope: "remarks",
        type: "string",
      },
    ],
  },

  // Termin
  Termin: {
    tableOnServer: "termin",
    table: "termin",
    nameScope: "appointment",
    columns: [
      {
        nameOnServer: "NN_9999",
        nameLocal: "idCustomerAtClient_9999",
        nameScope: "idCustomerAtClient",
        type: "integer",
      },
      {
        nameOnServer: "Kunde_29",
        nameLocal: "Kunde_29",
        nameScope: "idCustomerAtServer",
        type: "integer",
      },
      {
        nameOnServer: "uuidCustomer_9999",
        nameLocal: "uuidCustomer",
        nameScope: "uuidCustomer",
        type: "string",
      },
      {
        nameOnServer: "Beginn_5",
        nameLocal: "Beginn_5",
        nameScope: "begin",
        type: "date",
      },
      {
        nameOnServer: "Ende_6",
        nameLocal: "Ende_6",
        nameScope: "end",
        type: "date",
      },
      {
        nameOnServer: "Dauer_7",
        nameLocal: "Dauer_7",
        nameScope: "durationHours",
        type: "number",
      },
      {
        nameOnServer: "Titel_8",
        nameLocal: "Titel_8",
        nameScope: "subject",
        type: "string",
      },
      {
        nameOnServer: "Ort_9",
        nameLocal: "Ort_9",
        nameScope: "location",
        type: "string",
      },
      {
        nameOnServer: "BemerkungAnfahrt_15",
        nameLocal: "BemerkungAnfahrt_15",
        nameScope: "remark",
        type: "string",
      },
    ],
  },

  // Projektzeiterfassung
  Projektzeiterfassung: {
    tableOnServer: "projektzeiterfassung",
    table: "projektzeiterfassung",
    nameScope: "timeRecording",
    columns: [
      {
        nameOnServer: "DatumZeitVon_5",
        nameLocal: "DatumZeitVon_5",
        nameScope: "begin",
        type: "date",
      },
      {
        nameOnServer: "ZeitBis_6",
        nameLocal: "ZeitBis_6",
        nameScope: "end",
        type: "date",
      },
      {
        nameOnServer: "Dauer_7",
        nameLocal: "Dauer_7",
        nameScope: "duration",
        type: "number",
      },
      {
        nameOnServer: "Auftrag_13",
        nameLocal: "Auftrag_13",
        nameScope: "idOrderAtServer",
        type: "integer",
      },
      {
        nameOnServer: "uuidOrder_9999",
        nameLocal: "uuidOrder",
        nameScope: "uuidOrder",
        type: "string",
      },
      {
        nameOnServer: "Kunde_14",
        nameLocal: "Kunde_14",
        nameScope: "idCustomerAtServer",
        type: "integer",
      },
      {
        nameOnServer: "uuidCustomer_9999",
        nameLocal: "uuidCustomer",
        nameScope: "uuidCustomer",
        type: "string",
      },
      {
        nameOnServer: "DummyInt1_29",
        nameLocal: "Maintenance_29",
        nameScope: "idMaintenanceAtServer",
        type: "integer",
      },
      {
        nameOnServer: "uuidMaintenance_9999",
        nameLocal: "uuidMaintenance",
        nameScope: "uuidMaintenance",
        type: "string",
      },
    ],
  },

  Bilder: {
    tableOnServer: "bild-tabelle 2",
    table: "bildTabelle2",
    nameScope: "images",
    columns: [
      {
        nameOnServer: "uuidParent_9999",
        nameLocal: "uuidParent",
        nameScope: "uuidParent",
        type: "string",
      },
      {
        nameOnServer: "Name_5",
        nameLocal: "Name_5",
        nameScope: "name",
        type: "string",
      },
      {
        nameOnServer: "Daten_6",
        nameLocal: "Daten_6",
        nameScope: "data",
        type: "string",
      },
      {
        nameOnServer: "Type_14",
        nameLocal: "Type_14",
        nameScope: "type",
        type: "number",
      },
      {
        nameOnServer: "IsLink_15",
        nameLocal: "IsLink_15",
        nameScope: "isLink",
        type: "boolean",
      },
    ],
  },

  Firma: {
    tableOnServer: "firma",
    table: "firma",
    nameScope: "company",
    columns: [
      {
        nameOnServer: "Name",
        nameLocal: "Name",
        nameScope: "name",
        type: "string",
      },
      {
        nameOnServer: "Strasse",
        nameLocal: "Strasse",
        nameScope: "street",
        type: "string",
      },
      {
        nameOnServer: "PLZ",
        nameLocal: "PLZ",
        nameScope: "zipCode",
        type: "string",
      },
      {
        nameOnServer: "Ort",
        nameLocal: "Ort",
        nameScope: "city",
        type: "string",
      },
      {
        nameOnServer: "Telefon",
        nameLocal: "Telefon",
        nameScope: "telephone",
        type: "string",
      },
      {
        nameOnServer: "Fax",
        nameLocal: "Fax",
        nameScope: "fax",
        type: "string",
      },
      {
        nameOnServer: "E_mail",
        nameLocal: "E_mail",
        nameScope: "email",
        type: "string",
      },
      {
        nameOnServer: "Homepage",
        nameLocal: "Homepage",
        nameScope: "homepage",
        type: "string",
      },
    ],
  },

  Gerät: {
    tableOnServer: "gerät",
    table: "geraet",
    nameScope: "device",
    columns: [
      {
        nameOnServer: "Seriennummer_5",
        nameLocal: "Seriennummer_5",
        nameScope: "serialNumber",
        type: "string",
      },
      {
        nameOnServer: "Bezeichnung_6",
        nameLocal: "Bezeichnung_6",
        nameScope: "name",
        type: "string",
      },
      {
        nameOnServer: "Artikel_7",
        nameLocal: "Artikel_7",
        nameScope: "idArticleAtServer",
        type: "number",
      },
      {
        nameOnServer: "uuidArticle_9999",
        nameLocal: "uuidArticle",
        nameScope: "uuidArticle",
        type: "string",
      },
      {
        nameOnServer: "EAN_9",
        nameLocal: "EAN_9",
        nameScope: "ean",
        type: "string",
      },
      {
        nameOnServer: "Kunde_11",
        nameLocal: "Kunde_11",
        nameScope: "idCustomerAtServer",
        type: "number",
      },
      {
        nameOnServer: "uuidCustomer_9999",
        nameLocal: "uuidCustomer",
        nameScope: "uuidCustomer",
        type: "string",
      },
      {
        nameOnServer: "Strasse_16",
        nameLocal: "Strasse_16",
        nameScope: "street",
        type: "string",
      },
      {
        nameOnServer: "PLZ_17",
        nameLocal: "PLZ_17",
        nameScope: "zipCode",
        type: "string",
      },
      {
        nameOnServer: "Ort_18",
        nameLocal: "Ort_18",
        nameScope: "city",
        type: "string",
      },
      {
        nameOnServer: "Standortbeschreibung_20",
        nameLocal: "Standortbeschreibung_20",
        nameScope: "locationDescription",
        type: "string",
      },
      {
        nameOnServer: "LetzterZaehlerstand_31",
        nameLocal: "LetzterZaehlerstand_31",
        nameScope: "lastMeterReading",
        type: "number",
      },
      {
        nameOnServer: "Bilder_43",
        nameLocal: "",
        nameScope: "idsPicturesAtServer",
        type: "string",
      },
      {
        nameOnServer: "uuidsImages_9999",
        nameLocal: "uuidsImages",
        nameScope: "uuidsImages",
        type: "string",
      },
    ],
  },

  Wartungsauftrag: {
    tableOnServer: "wartungsauftrag",
    table: "wartungsauftrag",
    nameScope: "maintenance",
    columns: [
      {
        nameOnServer: "WartungsNr_5",
        nameLocal: "WartungsNr_5",
        nameScope: "number",
        type: "string",
      },
      {
        nameOnServer: "Beginn_6",
        nameLocal: "Beginn_6",
        nameScope: "begin",
        type: "date",
      },
      {
        nameOnServer: "Kunde_7",
        nameLocal: "Kunde_7",
        nameScope: "idCustomerAtServer",
        type: "string",
      },
      {
        nameOnServer: "uuidCustomer_9999",
        nameLocal: "uuidCustomer",
        nameScope: "uuidCustomer",
        type: "string",
      },
      {
        nameOnServer: "Kategorie_9",
        nameLocal: "Kategorie_9",
        nameScope: "idMaintenanceCategoryAtServer",
        type: "number",
      },
      {
        nameOnServer: "uuidMaintenanceCategory_9999",
        nameLocal: "uuidMaintenanceCategory",
        nameScope: "uuidMaintenanceCategory",
        type: "string",
      },
      {
        nameOnServer: "_9999",
        nameLocal: "KategorieName",
        nameScope: "categoryName",
        type: "string",
      },
      {
        nameOnServer: "Status_11",
        nameLocal: "Status_11",
        nameScope: "idMaintenanceStatusAtServer",
        type: "number",
      },
      {
        nameOnServer: "uuidMaintenanceStatus_9999",
        nameLocal: "uuidMaintenanceStatus",
        nameScope: "uuidMaintenanceStatus",
        type: "string",
      },
      {
        nameOnServer: "_9999",
        nameLocal: "StatusName",
        nameScope: "statusName",
        type: "string",
      },
      {
        nameOnServer: "Artikel_15",
        nameLocal: "Artikel_15",
        nameScope: "idArticleAtServer",
        type: "number",
      },
      {
        nameOnServer: "uuidArticle_9999",
        nameLocal: "uuidArticle",
        nameScope: "uuidArticle",
        type: "string",
      },
      {
        nameOnServer: "Geraet_16",
        nameLocal: "Geraet_16",
        nameScope: "idDeviceAtServer",
        type: "string",
      },
      {
        nameOnServer: "uuidDevice_9999",
        nameLocal: "uuidDevice",
        nameScope: "uuidDevice",
        type: "string",
      },
      {
        nameOnServer: "SerienNr_17",
        nameLocal: "SerienNr_17",
        nameScope: "serialNumber",
        type: "string",
      },
      {
        nameOnServer: "Bearbeiter_18",
        nameLocal: "Bearbeiter_18",
        nameScope: "idEmployeeAtServer",
        type: "number",
      },
      {
        nameOnServer: "isOwn_9999",
        nameLocal: "Eigener",
        nameScope: "isOwn",
        type: "boolean",
      },
      {
        nameOnServer: "FehlerbeschreibungKunde_21",
        nameLocal: "FehlerbeschreibungKunde_21",
        nameScope: "faultDescription",
        type: "string",
      },
      {
        nameOnServer: "Bemerkung_22",
        nameLocal: "Bemerkung_22",
        nameScope: "remarks",
        type: "string",
      },
      {
        nameOnServer: "Abgeschlossen_59",
        nameLocal: "Abgeschlossen_59",
        nameScope: "done",
        type: "boolean",
      },
      {
        nameOnServer: "Diagnose_66",
        nameLocal: "Diagnose_66",
        nameScope: "diagnosis",
        type: "string",
      },
      {
        nameOnServer: "Bilder_69",
        nameLocal: "",
        nameScope: "idsPicturesAtServer",
        type: "string",
      },
      {
        nameOnServer: "uuidsImages_9999",
        nameLocal: "uuidsImages",
        nameScope: "uuidsImages",
        type: "string",
      },
      {
        nameOnServer: "_9999",
        nameLocal: "uuidImageSignature",
        nameScope: "uuidImageSignature",
        type: "string",
      },
      {
        nameOnServer: "Ende_77",
        nameLocal: "Ende_77",
        nameScope: "end",
        type: "date",
      },
    ],
  },

  Wartungskategorie: {
    tableOnServer: "wartungskategorie",
    table: "wartungskategorie",
    nameScope: "maintenanceCategory",
    columns: [
      {
        nameOnServer: "active",
        nameLocal: "active",
        nameScope: "active",
        type: "boolean",
      },
      {
        nameOnServer: "Bezeichnung_5",
        nameLocal: "Bezeichnung_5",
        nameScope: "name",
        type: "string",
      },
    ],
  },

  Wartungsstatus: {
    tableOnServer: "wartungsstatus",
    table: "wartungsstatus",
    nameScope: "maintenanceStatus",
    columns: [
      {
        nameOnServer: "active",
        nameLocal: "active",
        nameScope: "active",
        type: "boolean",
      },
      {
        nameOnServer: "Bezeichnung_5",
        nameLocal: "Bezeichnung_5",
        nameScope: "name",
        type: "string",
      },
    ],
  },

  Lagerbewegung: {
    tableOnServer: "lagerbewegung",
    table: "lagerbewegung",
    nameScope: "stockMovement",
    columns: [
      {
        nameOnServer: "Artikel_5",
        nameLocal: "Artikel_5",
        nameScope: "idArticleAtServer",
        type: "number",
      },
      {
        nameOnServer: "uuidArticle_9999",
        nameLocal: "uuidArticle",
        nameScope: "uuidArticle",
        type: "string",
      },
      {
        nameOnServer: "Bewegungsart_6",
        nameLocal: "Bewegungsart_6",
        nameScope: "idStockMovementTypeAtServer",
        type: "number",
      },
      {
        nameOnServer: "Datum_7",
        nameLocal: "Datum_7",
        nameScope: "date",
        type: "date",
      },
      {
        nameOnServer: "Menge_8",
        nameLocal: "Menge_8",
        nameScope: "amount",
        type: "number",
      },
      {
        nameOnServer: "Datensatz_28",
        nameLocal: "Datensatz_28",
        nameScope: "idRecordAtServer",
        type: "number",
      },
      {
        nameOnServer: "uuidMaintenance_9999",
        nameLocal: "uuidMaintenance",
        nameScope: "uuidMaintenance",
        type: "string",
      },
    ],
  },

  WartungsauftragMaterial: {
    tableOnServer: "wartungsauftrag material",
    table: "wartungsauftragmaterial",
    nameScope: "maintenanceMaterial",
    columns: [
      {
        nameOnServer: "Teil_5",
        nameLocal: "Teil_5",
        nameScope: "idPartAtServer",
        type: "number",
      },
      {
        nameOnServer: "uuidPart_9999",
        nameLocal: "uuidPart",
        nameScope: "uuidPart",
        type: "string",
      },
      {
        nameOnServer: "Menge_6",
        nameLocal: "Menge_6",
        nameScope: "amount",
        type: "number",
      },
      {
        nameOnServer: "Einzelpreis_7V",
        nameLocal: "Einzelpreis_7V",
        nameScope: "priceUnitV",
        type: "number",
      },
      {
        nameOnServer: "Gesamtpreis_8V",
        nameLocal: "Gesamtpreis_8V",
        nameScope: "priceSumV",
        type: "number",
      },
      {
        nameOnServer: "Wartungsauftrag_13",
        nameLocal: "Wartungsauftrag_13",
        nameScope: "idMaintenanceAtServer",
        type: "number",
      },
      {
        nameOnServer: "uuidMaintenance_9999",
        nameLocal: "uuidMaintenance",
        nameScope: "uuidMaintenance",
        type: "string",
      },
    ],
  },

  LagerdatenArtikel: {
    tableOnServer: "lagerdaten artikel",
    table: "lagerdatenartikel",
    nameScope: "lagerdatenArtikel",
    columns: [
      {
        nameOnServer: "active",
        nameLocal: "active",
        nameScope: "active",
        type: "boolean",
      },
      {
        nameOnServer: "Artikel_5",
        nameLocal: "Artikel_5",
        nameScope: "idArticleAtServer",
        type: "number",
      },
      {
        nameOnServer: "uuidArticle_9999",
        nameLocal: "uuidArticle",
        nameScope: "uuidArticle",
        type: "string",
      },
      {
        nameOnServer: "Lager_11",
        nameLocal: "Lager_11",
        nameScope: "idWarehouseAtServer",
        type: "number",
      },
      {
        nameOnServer: "uuidWarehouse_9999",
        nameLocal: "uuidWarehouse",
        nameScope: "uuidWarehouse",
        type: "string",
      },
      {
        nameOnServer: "Lagerstand_13",
        nameLocal: "Lagerstand_13",
        nameScope: "amount",
        type: "number",
      },
    ],
  },

  Steuersatz: {
    tableOnServer: "steuersatz",
    table: "steuersatz",
    nameScope: "taxRate",
    columns: [
      {
        nameOnServer: "active",
        nameLocal: "active",
        nameScope: "active",
        type: "boolean",
      },
      {
        nameOnServer: "Bezeichnung_5",
        nameLocal: "Bezeichnung_5",
        nameScope: "name",
        type: "string",
      },
      {
        nameOnServer: "Kurzzeichen_6",
        nameLocal: "Kurzzeichen_6",
        nameScope: "nameShort",
        type: "string",
      },
      {
        nameOnServer: "Gesamtprozentsatz_7",
        nameLocal: "Gesamtprozentsatz_7",
        nameScope: "percentTotal",
        type: "number",
      },
    ],
  },

  Lager: {
    tableOnServer: "lager",
    table: "lager",
    nameScope: "lager",
    columns: [
      {
        nameOnServer: "active",
        nameLocal: "active",
        nameScope: "active",
        type: "boolean",
      },
      {
        nameOnServer: "Bezeichnung_5",
        nameLocal: "Bezeichnung_5",
        nameScope: "name",
        type: "string",
      },
    ],
  },

  Artikelgruppe: {
    tableOnServer: "artikelgruppe",
    table: "artikelgruppe",
    nameScope: "articleGroup",
    columns: [
      {
        nameOnServer: "active",
        nameLocal: "active",
        nameScope: "active",
        type: "boolean",
      },
      {
        nameOnServer: "Bezeichnung_5",
        nameLocal: "Bezeichnung_5",
        nameScope: "name",
        type: "string",
      },
    ],
  },
};

try {
  module.exports = {
    dataModels,
  };
} catch (err) {
  console.log("caught err", err);
}
